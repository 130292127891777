import React, {Fragment} from 'react';
import Helmet from 'react-helmet';
import {graphql} from 'gatsby';

import Section from '@components/layout/Section';
import ImageCallout from '@components/ImageCallout';
import Layout from '@layouts';
import Hero from '@components/Hero';
import PreFooter from '@components/PreFooter';
import {stripTagsFromWagtail} from '@util';
import {SCHEMA_MAIN} from '@util/googleSchema';

export default ({data}: Object) => {
  const {page} = data.wagtail;
  const {
    seoTitle,
    searchDescription,
    heroHeadline,
    heroCopy,
    heroImage,
    teamMembers,
  } = page;
  const sectionRef = React.createRef();
  return (
    <Fragment>
      <Helmet>
        <html lang="en" />
        <title>{seoTitle}</title>
        <meta name="description" content={searchDescription} />
        <script type="application/ld+json">
          {JSON.stringify(SCHEMA_MAIN)}
        </script>
      </Helmet>
      <Layout>
        <Hero
          title={heroHeadline}
          background={`linear-gradient(rgba(20, 20, 20, 0.3), rgba(20, 20, 20, 0.3)), url(${heroImage.url})`}
          body={stripTagsFromWagtail(heroCopy)}
          scrollToLink
          copy={'Learn More'}
          sectionRef={sectionRef}
          noOffer
        />
        <div id="second-section" ref={sectionRef}></div>
        {teamMembers &&
          teamMembers.map(({eyebrow, headline, copy, image}, index) => {
            return (
              <Section key={headline} >
                <ImageCallout
                  eyebrow={eyebrow}
                  headline={headline}
                  alignItems="flex-start"
                  copy={stripTagsFromWagtail(copy)}
                  imageLeft={index % 2 !== 0}
                  imageSrc={image && image.url}
                  imgMaxHeight="600px"
                  textAlign="left"
                  dangerousCopy
                />
              </Section>
            );
          })}
        <PreFooter
          headline="Interested in becoming a resident?"
          copy="Get in touch with us to find your perfect Fetner apartment."
        />
      </Layout>
    </Fragment>
  );
};

export const query = graphql`
  fragment TeamFragment on Wagtail_TeamPage {
    seoTitle
    searchDescription
    heroHeadline
    heroCopy
    heroImage {
      url
    }
    heroCtaText
    heroCtaLink
    heroOfferHeadline
    heroOfferCopy
    teamMembers {
      eyebrow
      headline
      copy
      image {
        url
      }
    }
  }
  query {
    wagtail {
      page(slug: "team") {
        ...TeamFragment
      }
    }
  }
`;
